import React from "react"
import { graphql } from "gatsby"
import BlogArchive from "../components/BlogArchive/BlogArchive.js"

const Home = ({ data, pageContext }) => {
  return <BlogArchive data={data} pageContext={pageContext} />
}

export default Home

export const pageQuery = graphql`
  query WordPressPostArchive(
    $offset: Int!
    $postsPerPage: Int!
  ) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        author {
          node {
            id
            name
            firstName
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
            altText
          }
        }
      }
    }
    categoriesData: allWpCategory {
      nodes {
        id
        name
        count
        uri
      }
    }
    recentPosts: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 5
    ) {
      nodes {
        id
        title
        uri
      }
    }
  }
`;
