import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Image from "gatsby-image"

import Layout from "../Layout/Layout"
import Seo from "../seo"
import WidgetContainer from "../WidgetContainer/WidgetContainer"
import Category from "../SideNav/Category"
import RecentPosts from "../SideNav/RecentPosts"
import { trimMonthInDateString } from "../../../utils/clientSideHelpers"

import "./blogArchive.css"

const BlogArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  const seoTitle = data.wpCategory?.name || "Home"

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title={`${seoTitle} | All posts`} />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title={`${seoTitle} | All posts`} />
      <WidgetContainer>
        <div className="home-wrap">
          <div className="sideNav">
            <Category data={data.categoriesData} />
            <RecentPosts data={data.recentPosts} />
          </div>

          <div className="main-content">
            <ol className="main">
              {posts.map(post => {
                const title = post.title
                const subTitle = post.subtitleAcf?.text
                const postAuthor = `${post.author?.node?.firstName}`;
                const featuredImage = {
                  fluid:
                    post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                  alt: post.featuredImage?.node?.altText || ``,
                }
                return (
                  <li key={post.uri}>
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <header>
                        <h1>
                          <Link
                            to={post.uri}
                            itemProp="url"
                            aria-label={`Link to post, ${title} from title`}
                          >
                            <span itemProp="headline">
                              {parse(title)}
                            </span>
                          </Link>
                        </h1>

                        <p className="subtitle">
                          {subTitle && (
                            <small>{subTitle} </small>
                          )}
                          {postAuthor && (
                            <small>{postAuthor}</small>
                          )}
                          <small>
                            {trimMonthInDateString(
                              post.date
                            )}
                          </small>
                        </p>

                        <Link
                          to={post.uri}
                          aria-label={`Link to post, ${title} from featured image`}
                        >
                          {featuredImage?.fluid && (
                            <Image
                              fluid={featuredImage.fluid}
                              alt={featuredImage.alt}
                              style={{ marginBottom: 50 }}
                            />
                          )}
                        </Link>
                      </header>
                      <section itemProp="description">
                        {parse(post.excerpt)}
                      </section>
                    </article>
                  </li>
                );
              })}
            </ol>
            <nav>
              {previousPagePath && (
                <>
                  <Link to={previousPagePath}>Previous page</Link>
                  <br />
                </>
              )}
              {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
            </nav>
          </div>
        </div>
      </WidgetContainer>
    </Layout>
  )
}

export default BlogArchive
